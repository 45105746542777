import GLightbox from 'glightbox';

function gallery() {
  const lightbox = GLightbox({
    selector: '.js-gallery',
    touchNavigation: true,
    loop: true,
    autoplayVideos: true,
    slideEffect: 'fade',
  });
}

export default gallery;
