let hello = true;

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR");
  });
}


// CSS Imports
import 'swiper/css/swiper.css';
import 'glightbox/dist/css/glightbox.css';
import '../css/style.css';

// import '../sass/style.scss';


// NPM Imports
import 'lazysizes';

// AlpineJS
import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse'

window.Alpine = Alpine;
Alpine.plugin(collapse)
Alpine.start();


//VUE NPM MODULES
// import Vue from 'vue';
// import vueSmoothScroll from 'vue2-smooth-scroll';
//
// // My own Vue module config imports
// import Accordion from './vue/accordion.vue';
//
// Vue.use(vueSmoothScroll, {duration: 2000, updateHistory: false});
//
// // VUE RELATED CODE
// const app = new Vue({
//   delimiters: ['[[', ']]'],
//   el: '#app',
//
//   data: {
//     navOpen: false,
//     searchOpen: false,
//   },
//
//   methods: {
//     toggleNav: function () {
//       this.navOpen = !this.navOpen;
//     },
//     toggleSearch: function () {
//       this.searchOpen = !this.searchOpen;
//     },
//   },
//   components: {
//     Accordion
//   }
// });

//COMPONENTS IMPORTS
import swiperProjects from './components/swiper-projects';
import swiperAwards from './components/swiper-awards';
import swiperFeatureBar from "./components/swiper-featureBar";
import gallery from './components/gallery';

//INIT COMPONENTS
swiperProjects();
swiperAwards();
swiperFeatureBar();
gallery();
