import Swiper from 'swiper';

function swiperAwards() {
  var hasSwipers = document.getElementsByClassName('js-swiper-awards');
  if (hasSwipers.length > 0) {
    var swiperAwards = new Swiper('.js-swiper-awards', {
      centeredSlides: true,
      
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  }
}

export default swiperAwards;