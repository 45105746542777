import Swiper from 'swiper';

function swiperFeatureBar() {
  var hasSwipers = document.getElementsByClassName('js-swiper-featureBar');
  if (hasSwipers.length > 0) {
    var swiper = new Swiper('.js-swiper-featureBar', {
      autoplay: {
        delay: 5000,
        stopOnLastSlide: true,
        disableOnInteraction: true
      },
      centeredSlides: true,
      speed: 600,
      
      // Navigation arrows
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  }
}

export default swiperFeatureBar;


